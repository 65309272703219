<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <h2 class="zdtitle">缴费明细</h2>
            <el-button-group>
                <el-button size="mini" v-for="v of shopLevelSearchType" @click="setCondition('shopLevelSearchType',v.id)" :key="v.id" :type="QueryOrderShopLevel.shopLevelSearchType==v.id?'primary':''">{{v.text}}</el-button>
            </el-button-group>
            <el-date-picker v-model="searchTime" type="daterange" class="m-l-30" size="mini" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="settime">
            </el-date-picker>
            <el-button type="primary" class="float-right" plain size="mini" @click="confirm" v-if="enterprise && enterprise.enterpriseSecurityDepositFee>0">申请退回保证金</el-button>
            <el-table :data="prlductData.content" style="width: 100%;margin-top: 10px;">
                <el-table-column prop="orderBody" label="订单描述"></el-table-column>
                <el-table-column prop="id" label="订单编号"></el-table-column>
                <el-table-column prop="orderStatus" label="订单状态">
                    <template slot-scope="scope">
                        <span v-if='scope.row.orderStatus==0'>待支付</span>
                        <span v-if='scope.row.orderStatus==1'>支付成功</span>
                        <span v-if='scope.row.orderStatus==2'>失效订单</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="支付方式">
                    <!-- 支付渠道: 0 微信; 1 支付宝; 4 余额支付; 5余额微信组合支付; 6余额支付宝组合支付-->
                    <div slot-scope="scope">

                        <span v-if="+scope.row.orderPayChanal===0" class="payimg">
                            <img src="@/assets/images/stockCar/WeChat.png" style="margin-right: 5px">微信支付</span>
                        <span v-else-if="+scope.row.orderPayChanal===1" class="payimg">
                            <img src="@/assets/images/stockCar/Alipay.png" style="margin-right: 5px">支付宝</span>
                        <span v-else-if="+scope.row.orderPayChanal===4">余额支付</span> 
                        <span v-else-if="+scope.row.orderPayChanal===5">余额微信组合支付</span> 
                        <span v-else-if="+scope.row.orderPayChanal===6">余额支付宝组合支付</span>    
                    </div>
                </el-table-column>
                <el-table-column prop="balanceChangeDetail" label="下单用户">
                    <template slot-scope="scope">
                        <div class="df-ai-c">
                            <img v-if="scope.row.orderCreateuserAvata" :src="$oucy.ossUrl+scope.row.orderCreateuserAvata" style="width:24px;height:24px;border-radius: 24px">{{scope.row.orderCreateuserNickname}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="支付金额" width="250">
                    <div slot-scope="scope">
                        <div v-if="scope.row.enterpriseShopLevelPayfeeOfOrderFeeVip"><span>vip年服务费金额:</span><span class="c-m">￥{{scope.row.enterpriseShopLevelPayfeeOfOrderFeeVip.payfeeAmount||0}}</span></div>
                        <div v-if="scope.row.orderFeeEarnest"><span>保证金金额:</span><span class="c-m" >￥{{scope.row.orderFeeEarnest||0}}</span></div>
                    </div>
                </el-table-column>
                <el-table-column prop="orderPaytime" label="时间"></el-table-column>
                <el-table-column label="操作" v-if="0">
                    <div slot-scope="scope">
                        <el-button type="primary" plain size="mini">申请退回</el-button>
                    </div>
                </el-table-column>
            </el-table>
            <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { enterpriseinfo } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            shopLevelSearchType: [
                { text: '全部', id: 0 },
                { text: '保证金', id: 1 },
                { text: 'vip', id: 2 },
            ],
            prlductData: {},
            QueryOrderShopLevel: {
                shopLevelSearchType: 0,
                startTime: null,
                endTime: null,
                enterpriseId: null,
                limit: 20,
                start: 0,
            },
            searchTime: [],
            enterprise:{}
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.QueryOrderShopLevel.enterpriseId = this.enterpriseId
        this.queryOrderShopLevel()
        this.enterprise=enterprise
    },
    methods: {
        // 切换
        setCondition(t, v) {
            this.QueryOrderShopLevel[t] = v
            this.queryOrderShopLevel();
        },
        settime() {
            if (this.searchTime && this.searchTime.length) {
                this.QueryOrderShopLevel.startTime = this.searchTime[0]
                this.QueryOrderShopLevel.endTime = this.searchTime[1]
            } else {
                this.QueryOrderShopLevel.startTime = null
                this.QueryOrderShopLevel.endTime = null
            }
            this.queryOrderShopLevel();
        },
        sizeChange: function(limit) {
            const me = this;
            me.QueryOrderShopLevel.limit = limit;
            me.queryOrderShopLevel();
        },
        currentChange: function(current) {
            const me = this;
            me.QueryOrderShopLevel.start = current - 1;
            me.queryOrderShopLevel();
        },
        queryOrderShopLevel() {
            enterpriseinfo.queryOrderShopLevel(this.QueryOrderShopLevel).then(res => {
                this.prlductData = res
            })
            console.log('submit!');
        },
        confirm(){            
            this.$confirm(`否确认申请退回当前保证金¥${this.enterprise.enterpriseSecurityDepositFee}元，申请审核通过后保证金将退回到个人钱包余额中！`, '申请退回保证金', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('点了确认')
                this.refundMargin()
            }).catch(() => {
                console.log('点了取消')
            });
        },
        refundMargin() {
            enterpriseinfo.refundMargin(this.QueryOrderShopLevel).then(res => {
                this.enterprise.enterpriseSecurityDepositFee=0
                localSet('enterprise',this.enterprise)
                this.$message('操作成功')
                // this.prlductData = res
            })
            // console.log('submit!');
        }
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}
</style>